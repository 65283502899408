$(() => {
	const cookies = {

		el: {
			body: $('body'),
			barHtml: $('<div class="cookie__bar"></div>)')
		},
		data: {},
		dataOptional: {},
		vars: {
			isActive: 'false',
			hexaColor: /(?:[0-9a-f]{3}){1,2}$/i,
			digits: /\d+/
		},

		cookiesUiBar(el) {

			//check if html exist in page
			if (!this.el.barHtml.length) return

			//assign cookie bar html element to "this" object
			this.el.cookiesBar = this.el.barHtml

			//show cookie bar
			this.el.cookiesBar.hide().appendTo(el).fadeIn(1500)

			//get and set data attr from html
			this.data.mainColor = el.data('cookies-color-main').match(this.vars.hexaColor)
			this.data.secondaryColor = el.data('cookies-color-secundary').match(this.vars.hexaColor)
			this.data.msgColor = el.data('cookies-msg-color')
			this.data.radius = el.data('cookies-border-radius')
			this.data.position = el.data('cookies-position')
			this.data.copy = el.data('cookies-text') ? el.data('cookies-text') : 'By continuing to browse or by clicking "Accept All Cookies", you agree to the storing of first and third-party cookies on your device to enhance site navigation, analyze site usage, and assist in our marketing efforts';
			this.data.target = el.data('cookies-target') ? el.data('cookies-target') : '_blank'
			this.data.policyLink = el.data('cookies-policy-link') ? el.data('cookies-policy-link') : ''
			this.data.policyText = el.data('cookies-policy-text') ? el.data('cookies-policy-text') : ''
			this.data.settingsBtn = el.data('cookies-btn-settings') ? el.data('cookies-btn-settings') : 'Cookie Settings'
			this.data.acceptBtn = el.data('cookies-btn-accept') ? el.data('cookies-btn-accept') : 'Accept all Cookies'
			this.data.rejectBtn = el.data('cookies-btn-reject') ? el.data('cookies-btn-reject') : ''

			//check if needs polcy link
			this.dataOptional.policyLink = this.data.policyLink ? `<a href="${this.data.policyLink}"target="${this.data.target}" class="cookie__bar-link">${this.data.policyText}</a>` : ''

			//check if needs reject button
			this.dataOptional.rejectBtn = this.data.rejectBtn ? `<a href="javascript:;" class="btn--reject cookie-btn js-cookies-reject">${this.data.rejectBtn}</a>` : ''

			//create cooike bar html
			this.el.cookiesBar.html(`
				<div class="cookie__container">
					<div class="cookie__bar-text">
						<span>${this.data.copy}</span>
						${this.dataOptional.policyLink}
					</div>
					<a href="javascript:;" class="cookie-btn btn--settings js-cookies-settings">
						${this.data.settingsBtn}
					</a>
					${this.dataOptional.rejectBtn}
					<a href="javascript:;" class="cookie-btn btn--allow js-cookies-accept">
						${this.data.acceptBtn}
					</a>
				</div>
			`)
		},

		cookiesUiModal(el) {

			//check if html exist in page
			if (!this.el.barHtml.length) return

			//assign cookie modal html element to "this" object
			this.el.cookiesModal = $('<div class="cookie__modal"></div>')

			//get and set data attr from html			
			this.data.title = el.data('cookies-modal-title') ? el.data('cookies-modal-title') : 'Privacy Preference Center'
			this.data.tabPrivacy = el.data('cookies-tab-privacy') ? el.data('cookies-tab-privacy') : 'Your Privacy'
			this.data.tabPrivacyText = el.data('cookies-tab-privacy-text') ? el.data('cookies-tab-privacy-text') : 'When you visit any web site, it may store or retrieve information on your browser, mostly in the form of cookies. This information might be about you, your preferences, your device or used to make the site work as you expect it to. The information does not usually identify you directly, but it can give you a more personalized web experience. You can choose not to allow some types of cookies. Click on the different category headings to find out more and change our default settings. However, you should know that blocking some types of cookies may impact your experience on the site and the services we are able to offer.'
			this.data.tabMandatory = el.data('cookies-tab-mandatory') ? el.data('cookies-tab-mandatory') : 'Strictly Necessary Cookies'
			this.data.tabMandatoryText = el.data('cookies-tab-mandatory-text') ? el.data('cookies-tab-mandatory-text') : 'These cookies are necessary for the website to function and cannot be switched off in our systems. They are usually only set in response to actions made by you which amount to a request for services, such as setting your privacy preferences, logging in or filling in forms. You can set your browser to block or alert you about these cookies, but some parts of the site may not work then.'
			this.data.tabMandatoryLabel = el.data('cookies-tab-mandatory-label') ? el.data('cookies-tab-mandatory-label') : 'Always Active'
			this.data.tabOptional = el.data('cookies-tab-optional') ? el.data('cookies-tab-optional') : 'Performance and Targeting Cookies'
			this.data.tabOptionalText = el.data('cookies-tab-optional-text') ? el.data('cookies-tab-optional-text') : 'Performance Cookies: These cookies allow us to count visits and traffic sources, so we can measure and improve the performance of our site. They help us know which pages are the most and least popular and see how visitors move around the site. All information these cookies collect is aggregated and therefore anonymous. If you do not allow these cookies, we will not know when you have visited our site.<br/>Targetting Cookies: These cookies are set through our site by our advertising partners. They may be used by those companies to build a profile of your interests and show you relevant ads on other sites. They work by uniquely identifying your browser and device. If you do not allow these cookies, you will not experience our targeted advertising across different websites.'
			this.data.tabOptionalLabel = el.data('cookies-tab-optional-label') ? el.data('cookies-tab-optional-label') : 'Active'
			this.data.saveSettingsBtn = el.data('cookies-btn-save') ? el.data('cookies-btn-save') : 'Save Settings'
			this.data.logo = el.data('cookies-logo') ? el.data('cookies-logo') : ''

			this.el.cookiesModal.html(`
				<div class="cookie__modal-container">
					<div class="cookie__modal-header">
						<div class="cookie__modal-close">
							<a href="javascript:;" class="cookie__modal-btn-close cookie-btn js-cookies-close">X</a>
						</div>
						<div class="cookie__modal-logo">
							<img src="${this.data.logo}" alt="logo"></div>
						<div class="cookie__modal-title">${this.data.title}</div>
					</div>
					<div class="cookie__modal-body">
						<div class="cookie__modal-tabs">
							<ul class="js-cookies-tabs">
								<li data-tab="cookies-privacy">${this.data.tabPrivacy}</li>
								<li data-tab="cookies-mandatory">${this.data.tabMandatory}</li>
								<li data-tab="cookies-optional">${this.data.tabOptional}</li>
							</ul>
							<div class="cookie__modal-tabs-content" data-cookies-tab-id="cookies-privacy">
								<div class="cookie__modal-title">${this.data.title}</div>
								<div class="cookie__modal-tabs-content-title"> <span>${this.data.tabPrivacy}</span></div>
								<div class="cookie__modal-tabs-content-text">${this.data.tabPrivacyText}</div>
							</div>
							<div class="cookie__modal-tabs-content" data-cookies-tab-id="cookies-mandatory">
								<div class="cookie__modal-title">${this.data.title}</div>
								<div class="cookie__modal-tabs-content-title"> <span>${this.data.tabMandatory}</span>
									<label>${this.data.tabMandatoryLabel}</label>
								</div>
								<div class="cookie__modal-tabs-content-text">${this.data.tabMandatoryText}</div>
							</div>
							<div class="cookie__modal-tabs-content" data-cookies-tab-id="cookies-optional">
								<div class="cookie__modal-title">${this.data.title}</div>
								<div class="cookie__modal-tabs-content-title"> <span>${this.data.tabOptional}</span>
									<input id="cookie__checkbox" class="js-cookies-checkbox" type="checkbox">
									<label for="cookie__checkbox"> <span>${this.data.tabOptionalLabel}</span></label>
								</div>
								<div class="cookie__modal-tabs-content-text">${this.data.tabOptionalText}</div>
							</div>
						</div>
					</div>
					<div class="cookie__modal-footer">
						<a href=javascript:;" class="cookie__modal-btn cookie-btn js-cookies-save">${this.data.saveSettingsBtn}</a>
					</div>
				</div>
			`).appendTo('body')
		},

		cookiesUiLayout() {

			//check if html exist in page
			if (!this.el.barHtml.length) return

			//cookies bar Layout
			this.data.position === 'top' ? this.el.cookiesBar.addClass('top') : this.el.cookiesBar.addClass('bottom')
			this.data.msgColor === 'white' ? this.el.cookiesBar.addClass('text--white') : this.el.cookiesBar.addClass('text--black')
			this.el.cookiesBar.css('background-color', `#${this.data.secondaryColor}`)
			this.el.cookiesBar.find('.cookie-btn:not(.btn--settings)').css('background-color', `#${this.data.mainColor}`)
			this.el.cookiesBar.find('.cookie__bar-link, .btn--settings').css('color', `#${this.data.mainColor}`)
			this.el.cookiesBar.find('.cookie-btn').css({
				'border-radius': `${this.data.radius}px `,
				'border-color': `#${this.data.mainColor}`
			})

			//cookies modal layout
			this.el.cookiesModal.find('.cookie__modal-container').css('background-color', `#${this.data.mainColor}`) //--set background color in container to fix a bug that happens sometimes

			this.el.cookiesModal.find('.cookie__modal-header, .cookie__modal-footer').css('background-color', `#${this.data.mainColor}`)
			this.el.cookiesModal.find('.cookie-btn').css({
				'border-radius': `${this.data.radius}px `,
				'color': `#${this.data.mainColor}`
			})
			this.data.radius > 1 ? this.el.cookiesModal.find('label[for="cookie__checkbox"]').css('border-radius', `${this.data.radius}px`) : ''
			this.el.cookiesModal.find('.cookie__modal-container').css('border-radius', this.data.radius >= 15 ? '15px' : `${this.data.radius}px`)
		},

		cookiesUiEvents(el) {
			//check if html exist in page
			if (!this.el.barHtml.length) return

			//assign cookie elements to "this" object
			this.el.cookiesBtnClose = $('.js-cookies-close')
			this.el.cookiesBtnSave = $('.js-cookies-save')
			this.el.cookiesBtnReject = $('.js-cookies-reject')
			this.el.cookiesBtnAccept = $('.js-cookies-accept')
			this.el.cookiesBtnSettings = $('.js-cookies-settings')
			this.el.cookiesTabs = $('.js-cookies-tabs>li')
			this.el.cookiesTabsContent = $('.cookie__modal-tabs-content')
			this.el.cookiesTabsAll = $('.js-cookies-tabs>li,.cookie__modal-tabs-content')
			this.el.cookiesCheckbox = $('.js-cookies-checkbox')


			//tabs in modal -- items on left side
			this.el.cookiesTabs.on('click', e => {
				const dataTab = $(e.currentTarget).data('tab')
				this.el.cookiesTabsAll.removeClass('cookie-current')
				$(e.currentTarget).addClass('cookie-current')
				$(`[data-cookies-tab-id=${dataTab}]`).addClass('cookie-current')
			})

			//open modal -- btn settings
			this.el.cookiesBtnSettings.on('click', e => {
				e.preventDefault()
				this.el.cookiesModal.addClass('active')
				this.el.body.css('overflow', 'hidden')
				this.el.cookiesTabsAll.removeClass('cookie-current')
				this.el.cookiesTabs.first().addClass('cookie-current')
				this.el.cookiesTabsContent.first().addClass('cookie-current')
			})

			//close modal -- X and btn save setting 
			this.el.cookiesBtnClose.add(this.el.cookiesBtnSave).on('click', e => {
				e.preventDefault()
				this.el.cookiesModal.removeClass('active')
				this.el.body.removeAttr('style')
			})

			this.el.cookiesBtnReject.add(this.el.cookiesBtnSave).on('click', e => {
				e.preventDefault()
				el.fadeOut(500)
			})

			this.el.cookiesCheckbox.on('change', e => {
				$(e.currentTarget).prop('checked') ?
					$(e.currentTarget).next().css({
						'background-color': `#${this.data.mainColor}`,
						'color': `#${this.data.mainColor}`,
						'border-radius': `${this.data.radius}px`
					}) :
					$(e.currentTarget).next().css({
						'background-color': '',
						'color': ''
					})

			})

		},

		cookiesFx(el) {

			//check if html exist in page
			if (!this.el.barHtml.length) return

			//Functionality -- create/read/delete cookies
			const getSetDelCookies = {
				//Create cookies
				setCookie(name, value, days) {
					let expires = ""

					if (days) {
						const date = new Date()
						date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000))
						expires = date.toUTCString()
					}
					document.cookie = `${name}=${value || ""}; expires=${expires}; path=/`
				},
				//Read cookies
				getCookie(name) {
					const nameEQ = `${name}=`,
						ca = document.cookie.split(';')

					for (let i = 0; i < ca.length; i++) {
						let c = ca[i];
						while (c.charAt(0) == ' ') c = c.substring(1, c.length)
						if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length)
					}
					return null;
				},
				//Delete cookies
				deleteAllcokies() {
					const cookies = document.cookie.split("; ")

					for (let c = 0; c < cookies.length; c++) {
						const d = window.location.hostname.split(".")

						while (d.length > 0) {
							const cookieBase = `${encodeURIComponent(cookies[c].split(";")[0].split("=")[0])}=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=${d.join('.')} ;path=`
							const p = location.pathname.split('/')
							document.cookie = `${cookieBase}/`
							while (p.length > 0) {
								document.cookie = cookieBase + p.join('/')
								p.pop()
							}
							d.shift()
						}
					}
				}
			}


			//assign cookie vendors 		
			let gaId = el.data('cookies-gaid'),
				gtmId = el.data('cookies-gtmid'),
				hjId = el.data('cookies-hjid'),
				fbId = el.data('cookies-fbqid')

			//FIX Polyfill startsWith for IE  -  https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/startsWith
			if (!String.prototype.startsWith) {
				Object.defineProperty(String.prototype, 'startsWith', {
					value(search, pos) {
						pos = !pos || pos < 0 ? 0 : +pos
						return this.substring(pos, pos + search.length) === search
					}
				})
			}

			//check if Google analytics data-attr has UA- prefix due to conflict with irst versions of the code
			if (gaId)
				if (!gaId.startsWith("UA-"))
					gaId = `UA-${gaId}`

			//vendors list - trigger optIn and optOut
			const vendors = [{
					// 1
					optOut() {
						if (gaId) {
							console.log('Optout1')
							window[`ga-disable-UA-${gaId}`] = true
						}
					},
					optIn() {
						if (getSetDelCookies.getCookie('accepted-optional') !== 'false') {
							if (gaId) {
								console.log('Optin1')
								//revert OptOut
								window[`ga-disable-UA-${gaId}`] = false;

								(function (i, s, o, g, r, a, m) {
									i['GoogleAnalyticsObject'] = r
									i[r] = i[r] || function () {
										(i[r].q = i[r].q || []).push(arguments)
									}, i[r].l = 1 * new Date()
									a = s.createElement(o),
										m = s.getElementsByTagName(o)[0]
									a.async = 1
									a.src = g
									m.parentNode.insertBefore(a, m)
								})(window, document, 'script', 'https://www.google-analytics.com/analytics.js', 'ga')
								ga('create', gaId, 'auto')
								ga('set', 'anonymizeIp', true)
								ga('set', 'forceSSL', true)
								ga('send', 'pageview')
							}
						}
					}
				},
				{
					// 2
					optOut() {
						if (gtmId)
							console.log('Optout2')
					},
					optIn() {
						if (getSetDelCookies.getCookie('accepted-optional') !== 'false') {
							if (gtmId) {
								console.log('Optin2');

								(function (w, d, s, l, i) {
									w[l] = w[l] || []
									w[l].push({
										'gtm.start': new Date().getTime(),
										event: 'gtm.js'
									});
									var f = d.getElementsByTagName(s)[0],
										j = d.createElement(s),
										dl = l != 'dataLayer' ? '&l=' + l : ''
									j.async = true
									j.src =
										'https://www.googletagmanager.com/gtm.js?id=' + i + dl
									f.parentNode.insertBefore(j, f)
								})(window, document, 'script', 'dataLayer', gtmId)
							}
						}
					}
				},
				{
					// 3
					optOut() {
						if (hjId)
							console.log('Optout3')
					},
					optIn() {
						if (getSetDelCookies.getCookie('accepted-optional') !== 'false') {
							if (hjId) {
								console.log('Optin3');

								(function (h, o, t, j, a, r) {
									h.hj = h.hj || function () {
										(h.hj.q = h.hj.q || []).push(arguments)
									};
									h._hjSettings = {
										hjid: hjId,
										hjsv: 5
									};
									a = o.getElementsByTagName('head')[0]
									r = o.createElement('script')
									r.async = 1
									r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv
									a.appendChild(r)
								})(window, document, '//static.hotjar.com/c/hotjar-', '.js?sv=')
							}
						}
					},
				},
				{
					// 4
					optOut() {
						if (fbId) {
							console.log('Optout4')
							getSetDelCookies.getCookie('accepted-optional') === 'false' ? fbq('init', '') : ''
						}
					},
					optIn() {
						if (getSetDelCookies.getCookie('accepted-optional') !== 'false') {
							if (fbId) {
								console.log('Optin4');

								! function (f, b, e, v, n, t, s) {
									if (f.fbq) return
									n = f.fbq = function () {
										n.callMethod ?
											n.callMethod.apply(n, arguments) : n.queue.push(arguments)
									}
									if (!f._fbq) f._fbq = n
									n.push = n
									n.loaded = !0
									n.version = '2.0'
									n.queue = []
									t = b.createElement(e)
									t.async = !0
									t.src = v
									s = b.getElementsByTagName(e)[0]
									s.parentNode.insertBefore(t, s)
								}(window, document, 'script',
									'https://connect.facebook.net/en_US/fbevents.js')
								fbq('init', fbId)
								fbq('track', "PageView")
							}
						}
					}
				}
			]

			this.el.cookiesCheckbox.on('change', e => {
				for (let i = 0; i < vendors.length; i++)
					$(e.currentTarget).prop('checked') ? this.vars.isActive = 'true' : this.vars.isActive = 'false'

			})

			//check if user has selected optional then mark checkbox as checked
			this.el.cookiesBtnSettings.on('click', e => {
				e.preventDefault()
				getSetDelCookies.getCookie('accepted-optional') === 'true' && !this.el.cookiesCheckbox.prop('checked') ? this.el.cookiesCheckbox.next().click() : ''
			})

			//save settings by user
			this.el.cookiesBtnSave.on('click', () => {
				for (let i = 0; i < vendors.length; i++)
					this.el.cookiesCheckbox.prop('checked') ? vendors[i].optIn() : vendors[i].optOut();

				!this.el.cookiesCheckbox.prop('checked') ? getSetDelCookies.deleteAllcokies() : ''
				getSetDelCookies.setCookie('accepted-optional', this.vars.isActive, 365)
			})

			//accept all cookies by user
			this.el.cookiesBtnAccept.on('click', () => {
				for (let i = 0; i < vendors.length; i++) {
					vendors[i].optIn()
					this.vars.isActive = 'true'
				}

				getSetDelCookies.setCookie('accepted-all', this.vars.isActive, 365)
				el.fadeOut(500)
			})

			// check if cookie is accepted to remove cookie bar
			if (getSetDelCookies.getCookie('accepted-all') || getSetDelCookies.getCookie('accepted-optional'))
				el.remove()

		},

		init(el) {
			const thisElement = $(el)
			//has to be called in this order -- do not change the order
			this.cookiesUiBar(thisElement)
			this.cookiesUiModal(thisElement)
			this.cookiesUiLayout()
			this.cookiesUiEvents(thisElement)
			this.cookiesFx(thisElement)
		}
	}

	cookies.init('.js-fever-cookies')
})